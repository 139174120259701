import React from 'react';
import OrderHistory from '../../../components/Account/OrderHistory';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const OrderHistoryPage = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute
      path="/en-AU/account/order-history"
      component={OrderHistory}
    />
  </ContextProvider>
);

export default OrderHistoryPage;
